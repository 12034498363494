<script>
/* eslint-disable */
</script>

<template>
      <!-- Display All Ingredients -->
      <div class="ingredients">
        <div class="row right-2">

            <div class="col-md-2 p-0 paddingClear" > <div class="w-b-1 pink font-weight-bold" @click="sendSauce(sauce)">{{ sauce }}</div> </div>

            <div class="col-md-2 p-0 paddingClear" v-for="ingredient in filteredIngredients" :key="ingredient.id" >
                <div class="w-b-1 square font-weight-bold" @click="sendIngredient(ingredient)"
                      v-bind:class='[ingredient.class_name, highlightDefToppings(ingredient) == true ? "active_default" : "",  highlightToppings(ingredient) == true ? "active" : ""]'>
                            <span>{{ ingredient.name }}  {{ mapping[ingredient.id] }}<br></span>
                  <div v-bind:class="[ deletedDefToppings(ingredient) ? 'cross': '']"> 
                  </div>
                </div>   
            </div>   
        </div>
      </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Home',
  props: {
      veganActive: {
        type: Boolean,
        default: false
      },
      loversActive: {
        type: Boolean,
        default: false
      },
      isHalfPizza: {
        type: String,
        default: 'no'
      },
      halfPizzaPart: {
        type: Number,
        default: -1
      },
      defaultToppings: {
        type: Array,
        default: () => []
      },
      toppings: {
        type: Array,
        default: () => []
      },
      mapping: {
        type: Object,
        default: () => {}
      },
      product: {
        type: Object,
        default: () => {}
      },
      sauce: {
        type: String,
        default: 'original'
      }

  },
  data(){
    return {
      ingredients: [],
      isHighlated: false,
    }
  },
  mounted() {
    // this.sauce = 'original';
    const TOKEN = localStorage.getItem("TOKEN");
    axios.request({
            method: 'post',
            url: this.$hostname + 'products/get-ingredients',
            headers: { 
              'Authorization': 'Bearer '+TOKEN, 
            }
          })
          .then(response => {
            this.ingredients = response.data;
          });
          
  },
  computed: {
      filteredIngredients () {
        if(this.loversActive){
          return this.ingredients.filter(x => x.product_category_id === 1 || x.product_category_id === 0);
        } else if(this.veganActive) {
          var ingredientsFilter = this.ingredients.filter(x => x.product_category_id === 1 || x.product_category_id === 0);
          var newArr = [];
          ingredientsFilter.forEach(x => {
            if(x.isPremium !== 4 || x.id == 3){
              console.log(x.name);
              // alert(x.name);
              newArr.push(x);
            }
            // if(x.id !== 3) {
            //   newArr.push(x);
            // }
          });
          return newArr;
        } else {
          // alert('NO CHEESE');
          var ingredientsFilter = this.ingredients.filter(x => x.product_category_id === 1 || x.product_category_id === 0);
          var newArr = [];
          
          ingredientsFilter.forEach(x => {
            if(x.isPremium !== 4 && x.id !== 3){
              // alert(x.name);
              newArr.push(x);
            }
            // if(x.id !== 3) {
            //   newArr.push(x);
            // }
          });
          return newArr;
          // return this.ingredients.filter( x=> x.isPremium === 4);
        }
      },
  },
  methods: {
    
    getToken() {
      var user_token = localStorage.getItem('TOKEN');
      return user_token;
    },
    highlightDefToppings(ingredient){
      for(var i = 0; i < this.defaultToppings.length; i++){   
          if(parseInt(this.defaultToppings[i].id) === ingredient.id){     
              return true;
          }
      }
    },
    deletedDefToppings(ingredient){
      for(var i = 0; i < this.defaultToppings.length; i++){   
          if(parseInt(this.defaultToppings[i].id) === ingredient.id && this.defaultToppings[i].isDeleted){
            return true;
          }
      }
    },
    highlightToppings(ingredient){
      for(var k = 0; k < this.toppings.length; k++){   
          if(parseInt(this.toppings[k].id) === ingredient.id){     
              return true;
          }
      }
    },
    falseHighlated(){
      this.isHighlated = false;
    },
    sendIngredient(ingredient){
      console.log('Product ingredient: ',ingredient);
      this.$emit('onAddTopping', ingredient);
    },
    sendSauce(sauce){
        this.$emit('onSendSauce', sauce);
    },
    sendClear(){
      this.$emit('onShowProductsClear');
    },
    sendAdd(){
      this.$emit('onShowProductsComponent');
    },
    deleteTopping(event, ingredient){

      event.stopPropagation();
      if (this.highlightDefToppings(ingredient)){
        this.$emit('onDeleteDefTopping', ingredient);
      }else if (this.highlightToppings(ingredient)){
        if(this.isHalfPizza == 'no'){
          this.$emit('onDeleteTopping', ingredient);
        }
        else if(this.isHalfPizza == 'yes' && this.halfPizzaPart == 1 || this.halfPizzaPart == 2){
          this.$emit('onDeleteCusTopping', ingredient);
        }
        else if(this.isHalfPizza == 'yes' && this.halfPizzaPart == 0 ){
          this.$emit('onDeleteCusMainTopping', ingredient);
        }
      }
    }
  }
}
</script>
